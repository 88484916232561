<template>
  <div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
        <b-spinner variant="primary" label="Text Centered" />     
    </div>
    <div v-else>
        <table-car-history-state :history="history"/>
    </div>
  </div>
</template>

<script>
import tableCarHistoryState from '@/views/component/Table/tableCarHistoryState.vue'
export default {
    components: {
        tableCarHistoryState
    },
data() {
    return {
      history: [],
      showPreloader: false,
    }
},
mounted() {
    this.refresh()
},
methods: {
    refresh() {
        this.showPreloader = true
        this.$http
        .get(`car-state?filter_performer_id=${this.$route.query.filter}`)
        .then(res => {
            this.history = res.data
            this.showPreloader = false
        })
    },
}
}
</script>

<style>

</style>